<template>
  <div class="page-banks animate__animated animate__fadeIn pt-4">
    <img
      :src="require('@/assets/banners/update-banks.png')"
      class="w-100 mb-4"
      alt=""
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>
    <h5 class="fs-la">Which bank cards would you like to update?</h5>
    <p class="fs-sm">Select as many providers as you like</p>

    <!-- Banks -->
    <!-- <div class="row">
      <div
        class="col-4 col-sm-3"
        v-for="(item, index) in banks"
        :key="index"
        style="padding: 4px 2px"
      >
        <div
          :class="`d-flex align-items-center justify-content-center m-1 p-1 squares h-100 hvr hvr-radial-out ${
            selectedBanks.includes(item) ? 'selected' : ''
          }`"
          @click="selectProvider(item)"
        >
          <div>
            <img
              :src="`${urlBucket}/${item.icon}`"
              @error="(e) => (e.target.src = `${urlBucket}/form/assets/banks.svg`)"
              class="img-fluid"
              alt=""
            />
            <p class="fw-bold mb-0">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="mb-3">
      <div class="input-search-container mb-3">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="name"
          ref="vSelect"
          :clearable="false"
          :options="banks"
          :placeholder="`Search Banks`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev, 'selectedBanks')"
        >
          <!-- :dropdown-should-open="() => true" -->
          <!-- banks -->
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <!-- <img
                :src="`${urlBucket}/${option.icon}`"
                width="30px"
                class="me-3"
                style="display: none"
                @load="
                  (e) => {
                    e.target.style.display = 'unset';
                    hideSpinner(option.name);
                  }
                "
              />
              <div :id="option.name" class="text-center me-3">
                <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
              </div> -->
              <p class="mb-0 fs-md">{{ option.name }}</p>
            </div>
          </template>

          <template slot="no-options">
            <div class="text-center">
              <p class="mb-1">Oops, it looks like there are no matching options.</p>
            </div>
          </template>

          <template #list-footer>
            <div class="text-center py-2 c-pointer" @click="showSuggestedSection()">
              <a class="mb-1 text-black fw-normal">Can't find the company you're looking for? 🤔</a>
            </div>
          </template>
        </vSelect>
      </div>

      <p v-if="errors.banks" style="color: #c64a45">* This field is required</p>

      <div class="chip-list-wrapper">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in selectedBanks"
          :key="index"
          @click="deleteService(index, 'selectedBanks')"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>

    <div class="suggested-companies" v-if="suggestedSection">
      <SuggestedCompany @inputValue="(e)=> suggestedCompany = e"></SuggestedCompany>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { banks } from "@/data/lists";
import { navigateTo } from "../router/index.js";
import vSelect from "vue-select";
import { setRoute } from "../router/setRoute.js";
import SuggestedCompany from '../components/SuggestedCompany';

// @ is an alias to /src

export default {
  name: "PageBanks",
  components: { vSelect,SuggestedCompany },
  data() {
    return {
      banks,
      suggestedCompany: '',
      suggestedSection: false,
      errors: { banks: false },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    updateValue(itemName, value) {
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    selectChange(value, typeService) {
      // console.log("selectChange", value);

      let array = this[typeService];

      if (array.includes(value)) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      } else {
        array.push(value);
      }

      this.$store.commit("formStore/setField", [typeService, array]);
    },
    deleteService(index, typeService) {
      let array = this[typeService];
      array.splice(index, 1);
      this.$store.commit("formStore/setField", [typeService, array]);
    },
    // selectProvider(value) {
    //   let array = this.selectedBanks;

    //   if (this.selectedBanks.includes(value)) {
    //     let index = array.indexOf(value);
    //     array.splice(index, 1);
    //   } else {
    //     array.push(value);
    //   }

    //   this.$store.commit("formStore/setField", [`selectedBanks`, array]);
    // },
    validateInputs() {
      //Banks
      if (this.selectedBanks.length == 0) {
        this.errors.banks = true;
      } else {
        this.errors.banks = false;
      }
    },
    showSuggestedSection() {
      this.suggestedSection = true;
      this.$refs.vSelect.closeSearchOptions();
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Your banks"]);
    const validatePage = () => {
      // let route = "/about-you";

      // const setRoute = () => {
      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }
      // };

      // setRoute();

      // this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {

        /* Suggested Companies */
        let array = this.suggestedCompanies
        array.push(this.suggestedCompany)
        this.updateValue('suggestedCompanies', array)

        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "selectedBanks",
      "informCouncilTax",
      "informEnergy",
      "informWaterSewerage",
      "informMobile",
      "informLoyalty",
      "informOpticians",
      "suggestedCompanies"
    ]),
    ...mapGetters("formStore", ["state"]),
  },
};
</script>
<style lang="scss">
.page-banks {
  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;

    p {
      font-size: 13px;
    }
    /*
   img {
      width: 60px;
      height: 55px;
    }
   */
  }

  .selected {
    background-color: #1cb0f6;
    color: white !important;
  }

  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }
    /*
    .vs__dropdown-menu {
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    */

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;
      cursor: pointer;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }

  .question p {
    line-height: 1.7;
  }
}
</style>
